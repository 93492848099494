import React from "react"
import { ErrorMessage } from "react-hook-form"
import JiggswapFormField from "./JiggswapFormField"

const JiggswapTextArea = ({ name, label, type, className, register, errors }) => {
  const hasErrors = errors && errors[name] !== undefined

  const labelText = label || name[0].toUpperCase() + name.substr(1)

  return (
    <JiggswapFormField label={labelText} fieldId={name}>
      <textarea
        aria-label={name}
        id={name}
        name={name}
        type={type}
        className={"textarea" + (hasErrors ? " is-danger" : "")}
        ref={register}
      />
      {hasErrors && (
        <p className="help is-danger">
          <ErrorMessage errors={errors} name={name} />
        </p>
      )}
    </JiggswapFormField>
  )
}

export default JiggswapTextArea
