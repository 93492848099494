import React from "react"

const JiggswapFormField = ({ label, fieldId, children }) => {
  return (
    <div className="field">
      <label className="label" htmlFor={fieldId}>
        {label}
      </label>
      <div className="control">{children}</div>
    </div>
  )
}

export default JiggswapFormField
