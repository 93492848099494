import React from "react"
import { Helmet } from "react-helmet-async"
import ContactForm from "../components/contact/ContactForm"

const ContactPage = () => {
  return (
    <>
      <Helmet title={"Contact - JiggSwap"} />

      <div className="site-content">
        <p className="is-size-2">Contact</p>
        <p className="is-size-5">
          Have a question? Comment? Run into a bug? Please let us know using the form below or by emailing us directly at{" "}
          <a href="mailto:andrew@jiggswap.com">andrew@jiggswap.com</a>.
        </p>

        <hr />

        <ContactForm />
      </div>
    </>
  )
}

export default ContactPage
