import React, { useState } from "react"

import { useForm } from "react-hook-form"
import jiggswapApi from "../../api/jiggswap"
import { MapApiErrors } from "../../helpers/ErrorParser"
import JiggswapTextArea from "../forms/JiggswapTextArea"
import JiggswapInput from "../forms/JiggswapInput"

const ContactForm = () => {
  const { register, handleSubmit, errors, setError } = useForm()

  const [loadingState, setLoadingState] = useState("ready")

  const isReady = loadingState === "ready"
  const isLoading = loadingState === "loading"
  const isSubmitted = loadingState === "submitted"

  const submitForm = async (values) => {
    setLoadingState("loading")

    try {
      await jiggswapApi.post("/contact", values)

      setLoadingState("submitted")
    } catch (err) {
      var errs = MapApiErrors(err.response.data.errors)

      setError(errs)
      setLoadingState("ready")
    }
  }

  return (
    <>
      {isLoading && <p className="has-text-centered">Submitting comment, please wait...</p>}
      {isSubmitted && <p className="has-text-centered">Thanks for the comment! We will get back to you!</p>}

      <form style={isReady ? {} : { display: "none" }} onSubmit={handleSubmit(submitForm)}>
        <div className="columns">
          <div className="column">
            <JiggswapInput name="name" label="Your Name" type="text" register={register} errors={errors} />
          </div>
          <div className="column">
            <JiggswapInput name="email" label="Email Address" type="email" register={register} errors={errors} />
          </div>
        </div>

        <JiggswapTextArea name="comment" label="Comments" type="textarea" register={register} errors={errors} />

        <button type="submit" className="button is-primary">
          Submit
        </button>
      </form>
    </>
  )
}

export default ContactForm
